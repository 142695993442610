import React from 'react'
import "./Header.css"
import SearchIcon from "@material-ui/icons/Search"
//import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import {Link} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux"
import { logOutInitiate } from '../../redux/actions'
import itemsrightupleftdownofficeactionresponseupdated from "../../../src/images/itemsrightupleftdownofficeactionresponseupdated.jpg"
//import aiozsseofficeactionresponse from "../../../src/images/aiozsseofficeactionresponse.JPG"
//import aiozsse from "../../../src/images/aiozsse.jpg"
//import itemsrightupleftdown from "../../../src/images/itemsrightupleftdown.JPG"
//import itemsrightupleftdownalt from "../../../src/images/itemsrightupleftdownalt.JPG"

const Header = () => {
    const { basket, user } = useSelector((state) => state.data);

    let dispatch = useDispatch();
    const handleAuth = () => {
        if (user) {
            dispatch(logOutInitiate());
        }
    };
    return (
        <nav className="header">
            <Link to="/">
                <img className="header-logo"
                src={itemsrightupleftdownofficeactionresponseupdated}
                alt=""
                style={{height: 70, marginBottom: 10}}
                //src={aiozsse}
                //alt="" 
                //style={{height: 50, marginBottom: 10}}
                //src="https://hdclipartall.com/images/picnic-clipart--1943x1404.png"
                //alt=""
                //style={{}}
                />
            </Link>
            <div className="search">
                <select style={{backgroundColor:"white"}}>
                    <option>All</option>
                </select>
                <input type="text" className="searchInput" />
                <SearchIcon className="searchIcon" />
            </div>
            <div className="header-nav">
                <Link to={`${user ? "/" : "/login"}`} className="header-link">
                    <div onClick={handleAuth} className="header-option">
                        <span className="header-option1" >Hello {user ? user.email : "Guest"},{" "}</span>
                        <span className="header-option2">{user ? "Sign Out" : "Sign In"}</span>
                    </div>
                </Link>
                <Link to="/orders" className="header-link">
                    <div className="header-option">
                        <span className="header-option1">Returns</span>
                        <span className="header-option2">& Orders</span>
                    </div>
                </Link>
                <Link to="/checkout" className="header-link">
                    <div className="header-basket">
                        <ShoppingCartOutlinedIcon />
                        <span className="header-option2 basket-count">
                            {basket && basket.length}
                        </span>
                    </div>
                </Link>
            </div>
        </nav>
    )
}

export default Header

/*
 <div className="header-option" style={{marginRight: "-10px"}}>
    <LocationOnOutlinedIcon />
</div>
*/

/*
 <Link to="/login" className="header-link">
    <div className="header-option">
        <span className="header-option1">Your</span>
        <span className="header-option1">Prime</span>
    </div>
</Link>
*/
