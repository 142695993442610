const products = [
  {
    id: "1",
    title: "Nike LeBron Zoom Soldier",
    price: 300,
    rating: 5,
    /*specification: [
      "Test",
    ],*/
    detail:
      "Men's Size 10",
    image:
    "/images/Shoes.jpg",
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
      seller: "Tom"
  },
  {
    id: "2",
    title: "Picnic For 4",
    price: 200,
    rating: 5,
    /*specification: [
      "Test",
    ],*/
    detail:
      "Message for available dates and times",
    image:
    "/images/Picnic.jpg",
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
      seller: "Lily"
  },
  {
    id: "3",
    title: "PlayStation 5",
    price: 600,
    rating: 5,
    /*specification: [
      "Test",
      ],*/
    detail:
      "Disc system including controller", 
    image:
    "/images/VideoGame.jpg",
      //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    seller: "Andre"
  },
  {
    id: "4",
    title: "FILA Sauna Vest",
    price: 20,
    rating: 5,
    /*specification: [
      "Test",
    ],*/
    detail:
      "Medium",
    image:
      "/images/Fitness.jpg",
        //"https://hdclipartall.com/images/picnic-clipart--1943x1404.png",
    seller: "Kyanna"
  },
];
  
  /*const headerItems = [
    "Home",
    "About",
    "Booking",
    "Contact",
    "FAQ",
    "Gallery",
    "Packages",
    "Support",
  ];*/
  
  export { products };
  //export { products, headerItems };