import React from "react";
import {Link} from "react-router-dom";
import "./SocialMedia.css"

function SocialMedia() {
    return (
        <div style={{backgroundColor:"white", display: "flex", justifyContent: "center", marginTop: 50}}>
        <div>
        <a href="https://www.facebook.com/aiozsse" class="btn social-icon button" data-abc="true">
            <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-facebook btn-round fa-lg"></i>
        </a>
        </div>
        <div>
        <a href="https://www.twitter.com/aiozsse" class="btn social-icon button" data-abc="true">
          <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-twitter btn-round fa-lg"></i>
        </a>
        </div>
        <div>
        <a href="https://www.instagram.com/aiozsse" class="btn social-icon button" data-abc="true">
            <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-instagram btn-round fa-lg"></i>
        </a>
        </div>
        <a href="https://www.snapchat.com/add/aiozsse" class="btn social-icon button" data-abc="true">
            <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-snapchat btn-round fa-lg"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCJGRai4lLMIPByegDVuQF4g" class="btn social-icon button" data-abc="true">
          <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-youtube btn-round fa-lg"></i>
        </a>
        {/*<a href="https://www.tiktok.com/@aiozsse" class="" data-abc="">
            <i style={{color: "#000", fontSize: 25, paddingRight: 10}} class="fa fa-tiktok"></i>
        </a>*/}
        <a href="https://www.pinterest.com/aiozsse/" class="btn social-icon button" data-abc="true">
            <i style={{color: "#000", fontSize: 25}} class="fa fa-pinterest btn-round fa-lg"></i>
        </a>
    </div>
    )
}

export default SocialMedia