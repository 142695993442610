import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Banner(){
    return (
        <div className="relative" style={{height: 400}}>
            <div className="absolute w-full">
            <Carousel 
                autoPlay
                infiniteLoop
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                interval={5000}
            > 
            <div>
                <img loading="lazy" src="images/aiozsseofficeactionresponseupdated.jpg" alt="" />
            </div>

                {/*<div>
                    <img loading="lazy" src="images/VideoGame.jpg" alt=""/>
                </div>
                <div>
                    <img loading="lazy" src="images/Shoes.jpg" alt="" />
                </div>
                <div>
                    <img loading="lazy" src="images/Picnic.jpg" alt="" />
                </div>*/}
            </Carousel>
            </div>
        </div>
    );
}

export default Banner